.Logo {
  display: inline-block;
  position: relative; }

.Logo--avatar {
  display: inline-block;
  width: 36px;
  height: 36px;
  transition: border-radius 0.2s linear;
  border-radius: 4px;
  background: #FFF;
  line-height: 46px;
  text-align: center;
  vertical-align: top; }
  .Logo--avatar i {
    color: #3C93D8;
    font-size: 22px; }
    .Logo--avatar i.Logo-icon--dark {
      color: #2E3C43; }

.Logo--footer {
  margin-right: 20px;
  background: #2E3C43; }
  .Logo--footer i {
    color: #FFF; }

.Logo--grey {
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 3px;
  background-color: #CCC;
  line-height: 48px;
  text-align: center; }
  .Logo--grey .CDB-IconFont {
    color: #FFF;
    font-size: 24px; }

.Logo-sub {
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  animation: fade-and-bounce-up 0.6s 350ms ease-in-out backwards;
  border-radius: 24px; }

.Logo-sub--google {
  background: url("/assets/1.0.0-assets.279/images/layout/google-platform-logo.png") no-repeat center center #FFFFFF; }

.Logo.is-loading .Logo--avatar {
  border-radius: 37px; }

.Logo.is-loading::after {
  animation: spin 0.7s linear infinite;
  opacity: 1; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

@keyframes fade-and-bounce-up {
  0% {
    transform: translateY(6px);
    opacity: 0; }
  15% {
    transform: translateY(-3px);
    opacity: 0.8; }
  45% {
    transform: translateY(2px);
    opacity: 1; }
  70% {
    transform: translateY(-1px); }
  100% {
    transform: translateY(0);
    opacity: 1; } }
